export default function useColors() {
    const colors = [
        '#b8254a',
        '#f41c35',
        '#ff007f',
        '#ff532a',
        '#ffa137',
        '#ffc40e',
        '#c6ae3b',
        '#90cf25',
        '#007643',
        '#048c7e',
        '#0686a1',
        '#007bb7',
        '#0558f2',
        '#438ffb',
        '#4dc6ff',
        '#4332c2',
        '#6e3dca',
        '#9b4cd6',
        '#757575',
        '#8ea4b4',
        '#4c3535',
        '#b97663',
        '#a58c6f',
        '#4d3636',
        '#313131',
        '#000000',
    ];

    return colors;
}
